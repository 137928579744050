<template>
  <div class="youth-homes" ref="printMe" v-if="hasPerm('youth.view_seanceinscription')">
    <page-header
      :title="title"
      icon="fa fa-children"
      :links="getLinks"
      :extra-links="getExtraLinks"
      class="no-print"
    >
    </page-header>
    <b-row class="sub-header page-left-title">
      <b-col cols="6">
        <b>
          {{ seanceDate|dateToString('dddd LL') }}
          <span class="hide-here">-</span>
        </b>
      </b-col>
      <b-col cols="6" class="text-right">
        <div v-if="!isLoading(loadingName)">
          <b>{{ getEltName() }}</b>
        </div>
      </b-col>
    </b-row>
    <div
      class="page-right-title hide-here"
      v-if="youthHomeElt.showBySeance"
    >
      <seances-indicator
        :seances="seances"
        :print-mode="printing"
        caption
      ></seances-indicator>
    </div>
    <div class="hide-here" v-if="printDate">Imprimé le {{ printDate }}</div>
    <b-row class="sub-header">
      <b-col :cols="counterFields.length ? 3 : 6">
        <div v-if="selectedSeances.length">
          <span class="badge badge-dark" v-for="seance of selectedSeances" :key="seance.id">
            {{ seance.getShortName() }}
          </span>
        </div>
        <div v-else><span class="badge badge-dark">Toutes les séances</span></div>
        <div v-if="workshops.length">
          <div v-if="selectedWorkshops.length">
            <span class="badge badge-dark" v-for="workshop of selectedWorkshops" :key="workshop.id">
              {{ workshop.name }}
            </span>
          </div>
          <div v-else><span class="badge badge-dark">Tous les ateliers</span> </div>
        </div>
        <b>
          <span v-if="showAllMembers">
            <counter-label :counter="inscriptionsCount" label="enfant" v-if="!isLoading(loadingName)">
            </counter-label>
            <span class="badge badge-dark badge-absences-count" v-if="!isLoading(loadingName)">
              <counter-label :counter="presencesCount" label="inscrit" ></counter-label>
            </span>
            <div>
              <b-form-checkbox v-model="onlyPresentMembers">Seulement les inscrits</b-form-checkbox>
            </div>
          </span>
          <span v-else>
            <counter-label :counter="inscriptionsCount" label="inscrit" v-if="!isLoading(loadingName)">
            </counter-label>
            <span class="badge badge-danger badge-absences-count" v-if="absencesCount && !isLoading(loadingName)">
              <counter-label :counter="absencesCount" label="absent" ></counter-label>
            </span>
          </span>
        </b>
      </b-col>
      <b-col v-if="counterFields.length" cols="3">
        <div v-for="field of counterFields" :key="field.id">
          <div
            class="badge clickable"
            :class="isFieldFiltered(field) ? 'badge-danger' : 'badge-light'"
            @click.prevent="filterField(field)"
          >
            {{ field.text }}: {{ getFieldCounter(field) }}
          </div>
        </div>
      </b-col>
      <b-col cols="6" class="text-right">
        <div v-if="!isLoading(loadingName)">
          <day-time-counters
            :elements="filteredElements"
            :base-elements="elements"
            selector
            @click="onDayTimeSelected($event)"
            :labels="dayTimeLabels"
          >
          </day-time-counters>
          <div v-if="youthHomeElt.showBySeance">
            <seance-counters :elements="filteredElements" :seances="seances"></seance-counters>
          </div>
        </div>
      </b-col>
    </b-row>
    <div v-show="false" ref="excelTable">
      <children-table :children="children"></children-table>
    </div>
    <div class="emails"  v-if="!showMain">
      <b-row>
        <b-col cols="10">
          <emails-groups
            v-if="showEmails && selectedSeparator"
            :emails="emails"
            :separator="selectedSeparator.value"
          >
          </emails-groups>
          <sms-list
            v-if="showMobiles && selectedSeparator"
            :mobile-phones="mobiles"
            :separator="selectedSeparator.value"
            :suffix="smsSuffix"
          ></sms-list>
        </b-col>
        <b-col cols="2">
          <b-form-group
            class="small-text"
            label="Séparateur"
            description="Les éléments seront séparés par ce caractère"
          >
            <emails-groups-separator-selector @change="selectedSeparator = $event.separator">
            </emails-groups-separator-selector>
          </b-form-group>
          <div v-if="showMobiles">
            <sms-list-operator-selector @change="smsSuffix = $event.smsSuffix" small-text>
            </sms-list-operator-selector>
          </div>
        </b-col>
      </b-row>
    </div>
    <div v-if="!isLoading(loadingName)" v-show="showMain">
      <div class="no-print">
        <b-row>
          <b-col sm="6" md="4">
            <b-form-select
              id="group-by"
              v-model="groupBy"
            >
              <b-form-select-option :value="elt" v-for="elt in groupByChoices" v-bind:key="elt.id">
                {{ elt.name }}
              </b-form-select-option>
            </b-form-select>
          </b-col>
          <b-col sm="4" :md="statusFilters.length ? 3 : 6">
            <input
              type="text"
              v-model="nameFilter"
              id="name-filter"
              class="form-control"
              placeholder="Nom ou prénom"
            />
          </b-col>
          <b-col sm="2" md="2">
            <b-form-checkbox
              id="show-birthdays"
              v-model="showBirthdays"
              :value="true"
              :unchecked-value="false"
              :disabled="birthdayCount === 0"
            >
              <counter-label :counter="birthdayCount" label="anniversaire" name0="Aucun">
              </counter-label>
            </b-form-checkbox>
          </b-col>
          <b-col sm="12" md="3" v-if="statusFilters.length">
            <b-form-select
              id="statusFilter"
              v-model="statusFilter"
              :class="{ filterSet: statusFilter > 0, }"
            >
              <b-form-select-option :value="elt.id" v-for="elt in statusFilters" :key="elt.id">
                {{ elt.name }}
              </b-form-select-option>
            </b-form-select>
          </b-col>
        </b-row>
      </div>
      <b-row>
        <b-col>
          <div
            v-for="limit of getDailyLimits()" :key="limit.id"
            class="limit-warning ut-daily-limit-warning no-print"
          >
            <span v-if="limit.limit">{{ limit.label }}: {{ limit.limit }}</span>
          </div>
        </b-col>
      </b-row>
      <div class="no-print">
        <b-row>
          <b-col md="12" lg="8">
            <div>
              <hour-element
                v-if="hasOpeningHours && showOpeningHours"
                :opening-hours="openingHours"
              ></hour-element>
            </div>
          </b-col>
          <b-col md="12" lg="4">
            <div class="no-print">
              <set-time-selector
                v-if="hasOpeningHours"
                v-model="clockStatus"
              ></set-time-selector>
            </div>
          </b-col>
        </b-row>
      </div>
      <div class="hide-here">
        <hour-element
          v-if="hasOpeningHours && showOpeningHours"
          :opening-hours="openingHours"
        ></hour-element>
      </div>
      <b-row>
        <b-col class="small-text no-print">
          <div v-if="seances.length > 1" class="checkbox-selector">
            <check-box-select
              :choices="seances"
              :nameCallback="getSeanceName"
              @changed="selectedSeances=$event.choices"
              :inline="true"
              :initial-value="selectedSeances"
            >
            </check-box-select>
          </div>
          <div v-if="workshops.length" class="checkbox-selector">
            <check-box-select
              :choices="workshops"
              @changed="selectedWorkshops=$event.choices"
              :inline="true"
            >
            </check-box-select>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="small-text no-print">
          <b-form-checkbox
            id="show-day-times"
            v-model="showDayTimes"
            name="show-day-times"
            :value="true"
            :unchecked-value="false"
            v-if="!youthHomeElt.showBySeance"
          >
            Affichage des moments de présence
          </b-form-checkbox>
          <b-form-checkbox
            id="show-parent-contact"
            v-model="showParentContact"
            :value="true"
            :unchecked-value="false"
          >
            Coordonnées des parents
          </b-form-checkbox>
        </b-col>
        <b-col class="no-print small-text">
          <b-form-checkbox
            id="show-seances-detail"
            v-model="showSeancesDetail"
            name="show-seances-detail"
            :value="true"
            :unchecked-value="false"
          >
            Affichage du pointage
          </b-form-checkbox>
          <b-form-checkbox
            v-if="showSeancesDetail && clockingSeance"
            id="show-absences"
            v-model="showAbsence"
            name="show-absences-detail"
            :value="true"
            :unchecked-value="false"
          >
            Pointer les absences
          </b-form-checkbox>
          <b-form-checkbox
            id="show-duration"
            v-model="showDuration"
            name="show-duration"
            v-if="seanceTypeElt.showDurationOnListing"
          >
            Affichage des durées
          </b-form-checkbox>
          <b-form-checkbox
            id="show-only-selected-seances"
            v-model="showOnlySelectedSeances"
            name="show-only-selected-seances"
            :value="true"
            :unchecked-value="false"
            v-if="(hasSelectedSeances && (showSeancesDetail || showDuration))"
          >
            Seulement les séances sélectionnées
          </b-form-checkbox>
        </b-col>
        <b-col class="no-print small-text">
          <b-form-checkbox
            id="printMode"
            v-model="printMode"
          >
            Affichage Impression
          </b-form-checkbox>
          <b-form-checkbox
            id="showOpeningHours"
            v-model="showOpeningHours"
            v-if="openingHours"
          >
            Affichage des horaires
          </b-form-checkbox>
        </b-col>
        <b-col class="text-right no-print small-text">
          <b-form-checkbox
            id="landscape"
            v-model="landscape"
            :value="true"
            :unchecked-value="false"
          >
            Impression en Paysage
          </b-form-checkbox>
          <b-select
            v-if="canBreakPage"
            id="pageBreak"
            v-model="pageBreak"
            name="pageBreak"
            class="small-select"
            style="display: inline-block; width: 240px;"
          >
            <b-select-option
              :value="choice.id"
              v-for="choice of pageBreakChoices"
              :key="choice.id"
            >
              {{ choice.name }}
            </b-select-option>
          </b-select>
        </b-col>
      </b-row>
    </div>
    <div v-show="showMain">
      <loading-gif :loading-name="loadingName"></loading-gif>
      <div class="doc-content">
        <div v-if="showDuration">
          <b-row class="header-block">
            <b-col>
              <b>Total</b>
            </b-col>
            <b-col class="text-right">
              <div v-if="cafModelsLoaded">
                <div>
                  <div class="duration-col-label">Réel</div>
                  <div class="duration-col-label">Facturé</div>
                </div>
                <div>
                  <div class="duration-col" title="Réel" v-b-tooltip>{{ getTotalRealDuration() }}</div>
                  <div class="duration-col" title="Facturé" v-b-tooltip>{{ getTotalPaidDuration() }}</div>
                </div>
              </div>
            </b-col>
          </b-row>
        </div>
        <div
          v-for="superGrouper of elementsByGroups"
          :key="superGrouper.id"
          class="super-grouper doc"
          :class="{ page: elementsByGroups.length > 1 && pageBreak === 1, }"
        >
          <b-row class="super-grouper-header" v-if="elementsByGroups.length > 1">
            <b-col cols="5">{{ superGrouper.name }}</b-col>
            <b-col cols="3" ></b-col>
            <b-col cols="4" class="text-right">
              <day-time-counters
                :elements="getSuperGrouperElements(superGrouper)"
                :labels="dayTimeLabels"
              >
              </day-time-counters>
              <a class="btn btn-secondary btn-sm ut-toggle-group no-print"
                 href @click.prevent="toggleSuperGroup(superGrouper)"
              >
                <i class="fa fa-chevron-circle-down no-print" v-if="!hiddenSuperGroups[superGrouper.id]"></i>
                <i class="fa fa-chevron-circle-right no-print" v-else></i>
              </a>
            </b-col>
          </b-row>
          <div v-show="!hiddenSuperGroups[superGrouper.id]" class="level1">
            <div
              v-for="grouper of getSuperGrouperGroups(superGrouper)"
              :key="grouper.id"
              class="grouper"
              :class="{ page: elementsByGroups.length === 1 || pageBreak !== 1, }"
            >
              <div>
                <b-row class="grouper-header">
                  <b-col cols="3">
                    {{ grouper.name }} <span v-if="statusFilter > 0">- {{ getStatusLabel(statusFilter) }}</span>
                    <span class="badge badge-dark">
                      <counter-label :counter="grouper.elements.length" label="inscrit"></counter-label>
                    </span>
                  </b-col>
                  <b-col cols="3">
                    <div v-if="grouper.limits.length" class="limit-warning ut-grouper-limit-warning">
                      <div v-for="limit of grouper.limits" :key="limit.id">
                        <span v-if="limit.limit">{{ limit.label }}: {{ limit.limit }}</span>
                      </div>
                    </div>
                    <div v-if="showSeancesDetail && !printMode">
                      <span
                        v-for="seance of seanceColumns"
                        :key="seance.id"
                        class="seance-col"
                      >
                        <div class="seance-col-label">{{ seance.getCodeOrAlias() }}</div>
                        <div title="cocher pour toutes les pré-inscriptions" v-b-tooltip v-if="hasPreInscriptions">
                          <a href @click.prevent="clickClockingColumn(grouper, seance)">
                            <i class="fa fa-check" v-if="seance.duration"></i>
                            <i class="fa fa-transparent" v-else></i>
                          </a>
                        </div>
                      </span>
                    </div>
                  </b-col>
                  <b-col cols="1" v-if="printMode && clockingColumns.length"></b-col>
                  <b-col :cols="printMode ? lastCol : 6" class="text-right">
                    <div v-if="showDuration && cafModelsLoaded">
                      <div>
                        <div class="duration-col-label">Réel</div>
                        <div class="duration-col-label">Facturé</div>
                      </div>
                      <div>
                        <div class="duration-col" title="Réel" v-b-tooltip>{{ getSumRealDuration(grouper) }}</div>
                        <div class="duration-col" title="Facturé" v-b-tooltip>{{ getSumPaidDuration(grouper) }}</div>
                      </div>
                    </div>
                    <day-time-counters
                      :elements="grouper.elements"
                      :labels="dayTimeLabels"
                    >
                    </day-time-counters>
                    <div v-if="showSeancesDetail && clockingSeance && printMode && clockingColumns.length === 0">
                      <span
                        v-for="seance of seanceColumns"
                        :key="seance.id"
                        class="seance-col"
                      >
                        <div class="seance-col-label">{{ seance.getCodeOrAlias() }}</div>
                        <div
                          title="cocher pour toutes les pré-inscriptions"
                          v-b-tooltip class="no-print" v-if="hasPreInscriptions"
                        >
                          <a href @click.prevent="clickClockingColumn(grouper, seance)">
                            <i class="fa fa-check" v-if="seance.duration"></i>
                            <i class="fa fa-transparent" v-else></i>
                          </a>
                        </div>
                      </span>
                    </div>
                    <clocking-columns
                      :clocking-columns="clockingColumns"
                      caption
                      v-if="printMode && showSeancesDetail && clockingColumns.length"
                    >
                    </clocking-columns>
                  </b-col>
                </b-row>
                <b-row
                  class="list-line list-line-full"
                  v-if="youthHomeElt.showBySeance && ((clockingColumns.length === 0) || !printMode)"
                >
                  <b-col>
                    <div class="text-right">
                      <seances-indicator
                        :seances="seances"
                        :print-mode="printing"
                        caption
                      ></seances-indicator>
                    </div>
                  </b-col>
                </b-row>
                <b-row
                  v-for="elt in grouper.elements"
                  :key="elt.individual.id"
                  class="list-line list-line-full not-striped dark-line"
                  :class="{ 'has-absence': hasAbsence(elt), 'clocking-done': isClockingDone(elt), 'still-here': isStillHere(elt)}"
                >
                  <b-col cols="3">
                    <div>
                      <inscription-fields :fields="markFields" :element="elt" show-as-mark>
                      </inscription-fields>
                      <a href @click.prevent="showIndividualSidebar(elt.individual)">
                        {{ elt.individual.lastAndFirstName() }}
                      </a>
                      <div v-if="getIndividualEntities(elt.individual).length" class="small-text">
                        <span v-for="entity of getIndividualEntities(elt.individual)" :key="entity.id">
                          <span
                            class="elt-entity"
                            :class="{'active-entity': entity.id === elt.entity.id}"
                            @click.prevent="setEltEntity(elt, entity)"
                          >
                            {{ entity.id }} {{ entity.name }}
                          </span>
                        </span>
                        <div class="small-text2" style="color: #888;">
                          La famille en sombre sera celle prise en compte pour les inscriptions.
                          Cliquez sur la famille pour changer.
                        </div>
                      </div>
                      <span
                        class="tiny-badge"
                        v-b-tooltip="elt.individual.birthDateAsString()"
                        v-if="elt.individual.birthDate"
                      >
                        <span
                          v-if="showIndividualBirthday(elt)"
                          v-b-tooltip="'Joyeux anniversaire ' + elt.individual.firstName"
                        >
                          <i class="fa fa-birthday-cake"></i>
                        </span>
                        <span @click.prevent="toggleBirthDate()" class="clickable show-birth-date">
                          <span v-if="showAge">{{ getAgeForGroup(elt.individual) }} ans</span>
                          <span v-if="hasCustomGroup(elt)"> -> {{ getCustomGroup(elt) }}</span>
                          <span v-if="showAge && showBirthDate"> - </span>
                          <span v-if="showBirthDate">{{ elt.individual.birthDateAsString() }}</span>
                        </span>
                      </span>
                      <span class="tiny-badge-bold" v-if="showSchoolClass">{{ getSchoolClassName(elt) }}</span>
                    </div>
                    <div v-if="showDocInfo && showIndividualLinkDocMissing(elt)" class="badge badge-error">
                      La fiche liaison est manquante
                    </div>
                    <div v-if="showDocInfo && showIndividualLinkDocOutdated(elt)" class="badge badge-warning">
                      La fiche liaison n'est pas à jour
                    </div>
                    <div
                      class="small words"
                      v-if="(!youthHomeElt.hideHandicapOnList) && (elt.child.handicapHelp || elt.child.personalHelp)"
                    >
                      <span v-if="elt.child.personalHelp">PAI</span>
                      <span v-if="elt.child.handicapHelp">AEEH</span>
                    </div>
                    <div class="small2" v-if="elt.individual.about && !options.hideIndividualAbout">
                      {{ elt.individual.about }}
                    </div>
                    <div class="small highlight" v-if="elt.child.warnings && !options.hideChildWarning">
                      {{ elt.child.warnings }}
                    </div>
                    <inscription-fields :fields="rowFields" :element="elt"></inscription-fields>
                    <div v-if="!printMode && showParentContact">
                      <inscription-parents :element="elt"></inscription-parents>
                    </div>
                  </b-col>
                  <b-col cols="3" v-if="colFields.length">
                    <inscription-fields :fields="colFields" :element="elt"></inscription-fields>
                  </b-col>
                  <b-col :cols="(!printMode && !colFields.length) ? 6 : 3">
                    <div v-if="printMode && showParentContact">
                      <inscription-parents :element="elt"></inscription-parents>
                    </div>
                    <div v-else-if="showSeancesDetail && !printMode">
                      <inscription-clocking
                        :element="elt"
                        :seances="seances"
                        :selected-seances="selectedSeances"
                        :can-switch="canSwitch"
                        :clock-status="clockStatus"
                        :has-opening-hours="hasOpeningHours"
                        :opening-hours="openingHours"
                        :day="day"
                        :show-moment-selector="youthHomeElt.showMomentSelector"
                        :presences-map="presencesMap"
                        :seance-period="seancePeriodElt"
                        :youth-home="youthHomeElt"
                        :seance-type="seanceTypeElt"
                        :show-only-selected-seances="showOnlySelectedSeances"
                        :rules="rules"
                        :check-seance="getSeanceColumnCheck(elt)"
                        :show-absence="showAbsence"
                        :show-evening="hasEvenings"
                        @seanceClicked="onSeanceClicked($event)"
                        @refreshed="onRefreshed($event)"
                        @prefixClicked="toggleClockSelector()"
                        @seances-updated="onSeancesUpdated(elt, $event)"
                        @seance-checked="resetSeanceColumnCheck($event)"
                      >
                      </inscription-clocking>
                    </div>
                  </b-col>
                  <b-col v-if="printMode && clockingColumns.length" cols="1">
                    <seance-inscription-moment-indicator
                      :inscriptions="elt.inscriptions"
                      v-if="showDayTimes"
                      :show-evening="hasEvenings"
                      :show-picnic="hasPicnic"
                      :show-lunch="hasLunch"
                      :smaller="true"
                      :labels="dayTimeLabels"
                      :letters="dayTimeLetters"
                    ></seance-inscription-moment-indicator>
                  </b-col>
                  <b-col
                    :cols="lastCol"
                    class="text-right last-col"
                  >
                    <div v-if="showDuration && cafModelsLoaded">
                      <div>
                        <div class="duration-col-label">Réel</div>
                        <div class="duration-col-label">Facturé</div>
                      </div>
                      <div>
                        <div class="duration-col" title="Réel" v-b-tooltip>{{ getRealDuration(elt) }}</div>
                        <div class="duration-col" title="Facturé" v-b-tooltip>{{ getPaidDuration(elt) }}</div>
                      </div>
                    </div>
                    <div v-if="printMode && showSeancesDetail">
                      <div v-if="clockingColumns.length">
                        <clocking-columns :clocking-columns="clockingColumns"></clocking-columns>
                      </div>
                      <div v-else>
                        <inscription-clocking
                          :element="elt"
                          :seances="seances"
                          :can-switch="canSwitch"
                          :clock-status="clockStatus"
                          :has-opening-hours="hasOpeningHours"
                          :opening-hours="openingHours"
                          :day="day"
                          :show-moment-selector="youthHomeElt.showMomentSelector"
                          :presences-map="presencesMap"
                          :seance-period="seancePeriodElt"
                          :youth-home="youthHomeElt"
                          :seance-type="seanceTypeElt"
                          :show-only-selected-seances="showOnlySelectedSeances"
                          :show-evening="hasEvenings"
                          :rules="rules"
                          :check-seance="getSeanceColumnCheck(elt)"
                          :show-absence="showAbsence"
                          @seanceClicked="onSeanceClicked($event)"
                          @refreshed="onRefreshed($event)"
                          @prefixClicked="toggleClockSelector()"
                          @seances-updated="onSeancesUpdated(elt, $event)"
                          name-before
                        >
                        </inscription-clocking>
                      </div>
                    </div>
                    <div>
                      <div
                        v-if="showDayTimes && !youthHomeElt.showBySeance && (!printMode || clockingColumns.length === 0)"
                        class="clickable print-margin-vertical"
                        @click.prevent="onChangeInscriptions(elt)"
                      >
                        <seance-inscription-moment-indicator
                          :inscriptions="elt.inscriptions"
                          :show-evening="hasEvenings"
                          :show-picnic="hasPicnic"
                          :show-lunch="hasLunch"
                          :labels="dayTimeLabels"
                          :letters="dayTimeLetters"
                        ></seance-inscription-moment-indicator>
                      </div>
                      <div
                        v-if="youthHomeElt.showBySeance && ((clockingColumns.length === 0) || !printMode)"
                        @click.prevent="onChangeInscriptions(elt)"
                      >
                        <seances-indicator
                          :seances="seances"
                          :print-mode="printing"
                          :inscriptions="elt.inscriptions"
                        ></seances-indicator>
                      </div>
                    </div>
                  </b-col>
                </b-row>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="updatedInscriptions.length" class="confirm-updated-inscriptions">
      <b-row>
        <b-col>
          <i class="fa fa-asterisk"></i>
          Vous avez <counter-label :counter="updatedInscriptionCount" label="inscription"></counter-label>
          à confirmer
        </b-col>
        <b-col cols="5" class="text-right">
          <a href @click.prevent="loadInscriptions" class="btn-sm btn btn-secondary">
            Annuler
          </a>
          &nbsp;
          <a href @click.prevent="onConfirmUpdatedInscriptions" class="btn btn-sm btn-primary">
            Confirmer
          </a>
        </b-col>
      </b-row>
    </div>
    <multi-inscriptions-modal
      v-if="youthHomeElt && multiInscriptionLoaded && seanceTypeElt"
      :day="seanceDateAsString"
      :elements="elements"
      :youth-home="youthHomeElt"
      :all-members="allMembers"
      :members-loaded="membersLoaded"
      :seance-type="seanceTypeElt"
      :period="seancePeriodElt"
      :opening-hours="openingHours"
      :has-opening-hours="hasOpeningHours"
      :rules="rules"
      @close="onMultiInscriptionsClosed"
    >
    </multi-inscriptions-modal>
    <multi-inscriptions-quick-modal
      v-if="youthHomeElt && multiInscriptionLoaded && seanceTypeElt"
      :day="seanceDateAsString"
      :elements="elements"
      :youth-home="youthHomeElt"
      :all-members="allMembers"
      :members-loaded="membersLoaded"
      :seance-type="seanceTypeElt"
      :period="seancePeriodElt"
      :seances="seances"
      :rules="rules"
      @refreshed="onMultiInscriptionsClosed"
    >
    </multi-inscriptions-quick-modal>
    <daily-inscriptions-modal
      v-if="youthHomeElt && seanceTypeElt && rulesLoaded"
      :day="seanceDateAsString"
      :element="inscriptionElement"
      :youth-home="youthHomeElt"
      :seance-type="seanceTypeElt"
      :period="seancePeriodElt"
      :rules="rules"
      @close="onDailyInscriptionsClosed()"
      @save="onDailyInscriptionsSaved()"
    >
    </daily-inscriptions-modal>
    <daily-inscriptions-and-pay-modal
      v-if="youthHomeElt && seanceTypeElt && rulesLoaded"
      :day="seanceDateAsString"
      :elements="inscriptionElements"
      :youth-home="youthHomeElt"
      :seance-type="seanceTypeElt"
      :period="seancePeriodElt"
      :rules="rules"
      @close="onDailyInscriptionsPayClosed()"
    >
    </daily-inscriptions-and-pay-modal>
    <switch-seance-inscription-modal
      :individual="selectedElement ? selectedElement.individual : null"
      :inscription="selectedInscription"
      :seances="seances"
      @done="onRefreshed($event)"
    >
    </switch-seance-inscription-modal>
    <opening-hours-modal
      modal-id="bv-opening-hours-modal"
      :seance-type="seanceTypeElt"
      :youth-home="youthHomeElt"
      :period="seancePeriodElt"
      :date="day"
      @refresh="loadInscriptions()"
    ></opening-hours-modal>
  </div>
</template>

<script>
// @ is an alias to /src
import moment from 'moment'
import { mapActions, mapMutations } from 'vuex'
import { loadLinkDocuments } from '@/apis/youth'
import CheckBoxSelect from '@/components/Controls/CheckBoxSelect'
import CounterLabel from '@/components/Controls/CounterLabel'
import EmailsGroups from '@/components/Controls/EmailsGroups'
import EmailsGroupsSeparatorSelector from '@/components/Controls/EmailsGroupsSeparatorSelector'
import LoadingGif from '@/components/Controls/LoadingGif'
import SmsList from '@/components/Controls/SmsList'
import PageHeader from '@/components/Layout/PageHeader'
import HourElement from '@/components/Seances/HourElement'
import SetTimeSelector from '@/components/Seances/SetTimeSelector'
import DailyInscriptionsModal from '@/components/Youth/DailyInscriptionsModal'
import DailyInscriptionsAndPayModal from '@/components/Youth/DailyInscriptionsAndPayModal'
import MultiInscriptionsModal from '@/components/Youth/MultiInscriptionsModal'
import MultiInscriptionsQuickModal from '@/components/Youth/MultiInscriptionsQuickModal'
import SeanceInscriptionMomentIndicator from '@/components/Youth/SeanceInscriptionMomentIndicator'
import ChildrenTable from '@/components/Youth/ChildrenTable'
import SeancesIndicator from '@/components/Seances/SeancesIndicator'
import SwitchSeanceInscriptionModal from '@/components/Youth/SwitchSeanceInscriptionModal'
import InscriptionFields from '@/components/Youth/SeanceInscriptionList/InscriptionFields'
import InscriptionParents from '@/components/Youth/SeanceInscriptionList/InscriptionParents'
import DayTimeCounters from '@/components/Youth/SeanceInscriptionList/DayTimeCounters'
import SeanceCounters from '@/components/Youth/SeanceInscriptionList/SeanceCounters'
import InscriptionClocking from '@/components/Youth/SeanceInscriptionList/InscriptionClocking'
import ClockingColumns from '@/components/Youth/SeanceInscriptionList/ClockingColumns.vue'
import { dateToString } from '@/filters/texts'
import { BackendMixin } from '@/mixins/backend'
import { makeActivityInscription } from '@/types/activities'
import { makeChoice } from '@/types/base'
import { makeChild } from '@/types/families'
import { makeIndividual } from '@/types/people'
import { makeCafModel } from '@/types/reports'
import { makeSchoolClass, makeSchoolLevelGroup } from '@/types/schools'
import {
  DayMoments,
  makeDailyListField,
  makeSeanceInscription,
  makeAgeGroup,
  getAgeGroup,
  makeYouthHome,
  GroupByChoice,
  makeSeanceType,
  makeSeanceLimits,
  makeSeancePeriod,
  makeOpeningHours,
  getAgeForGroup,
  getCustomAge,
  makeSeance,
  groupByChoices,
  makeInscriptionRule,
  MultiInscriptionsModalChoices,
  DayTimeValues,
  getInscriptionsDayTimes,
  makeDayListOptions
} from '@/types/youth'
import { BackendApi, openDocument } from '@/utils/http'
import { distinct, existsIn } from '@/utils/arrays'
import { isBirthday } from '@/utils/dates'
import { sum } from '@/utils/math'
import { emailsGrouper } from '@/utils/emails'
import { calculateRealDuration, calculatePaidDuration } from '@/utils/reports'
import { includes, slugify } from '@/utils/strings'
import { compareNumbers } from '@/utils/sorting'
import store from '@/store'
import OpeningHoursModal from '@/components/Youth/OpeningHoursModal.vue'
import SmsListOperatorSelector from '@/components/Controls/SmsListOperatorSelector.vue'

const pageBreakKey = 'youth-day-page-break'
const landscapeKey = 'youth-day-landscape'
const groupByKey = 'youth-day-group-by'
const printModeKey = 'youth-day-print-mode'
const showSeancesDetailKey = 'youth-day-seance-detail'
const showAbsenceKey = 'youth-day-seance-absence'
const showParentContactKey = 'youth-day-parent-contact'
const showDayTimesKey = 'youth-day-show-day-times'
const hideOpeningHoursKey = 'youth-day-hide-opening-hours'

export default {
  name: 'youth-home-day',
  mixins: [BackendMixin],
  components: {
    SmsListOperatorSelector,
    OpeningHoursModal,
    ClockingColumns,
    InscriptionClocking,
    SeanceCounters,
    DayTimeCounters,
    InscriptionParents,
    InscriptionFields,
    SeancesIndicator,
    SwitchSeanceInscriptionModal,
    EmailsGroupsSeparatorSelector,
    EmailsGroups,
    SeanceInscriptionMomentIndicator,
    CheckBoxSelect,
    SetTimeSelector,
    DailyInscriptionsModal,
    DailyInscriptionsAndPayModal,
    MultiInscriptionsModal,
    MultiInscriptionsQuickModal,
    HourElement,
    CounterLabel,
    LoadingGif,
    PageHeader,
    SmsList,
    ChildrenTable,
  },
  props: {
    day: String,
    youthHome: String,
    seanceType: String,
    seancePeriod: String,
  },
  data() {
    return {
      nameFilter: '',
      loadingName: 'youth-home-day',
      seanceDateAsString: '',
      elements: [],
      fields: [],
      schoolClasses: [],
      schoolLevelGroups: [],
      ageGroups: [],
      hiddenGroups: {},
      hiddenSuperGroups: {},
      seances: [],
      workshops: [],
      selectedSeances: [],
      selectedWorkshops: [],
      groupBy: null,
      youthHomeElt: makeYouthHome(),
      seanceTypeElt: makeSeanceType(),
      seancePeriodElt: makeSeancePeriod(),
      selectedMoment: DayMoments.None,
      clockStatus: 0,
      dayMoments: DayMoments,
      seanceLimits: null,
      missingDocIds: [],
      docsSavedOn: {},
      showDocInfo: false,
      multiInscriptionLoaded: false,
      openingHours: null,
      showSeancesDetail: !!(+(window.localStorage.getItem(showSeancesDetailKey) || 0)),
      showAbsence: !!(+(window.localStorage.getItem(showAbsenceKey) || 0)),
      showParentContact: !!(+(window.localStorage.getItem(showParentContactKey) || 0)),
      showDayTimes: !!(+(window.localStorage.getItem(showDayTimesKey) || 0)),
      showOnlySelectedSeances: false,
      showEmails: false,
      showMobiles: false,
      smsSuffix: false,
      presencesMap: new Map(),
      selectedSeparator: null,
      printMode: !!(+(window.localStorage.getItem(printModeKey) || 0)),
      showOpeningHours: !(+(window.localStorage.getItem(hideOpeningHoursKey) || 0)),
      printing: false,
      pageBreak: +(window.localStorage.getItem(pageBreakKey) || 1),
      landscape: !!(+(window.localStorage.getItem(landscapeKey) || 0)),
      selectedInscription: null,
      selectedElement: null,
      inscriptionElement: null,
      inscriptionElements: [],
      statusFilter: 0,
      showBirthdays: false,
      showAge: true,
      showBirthDate: false,
      showDuration: false,
      printDate: '',
      rules: [],
      rulesLoaded: false,
      seanceColumns: [],
      seanceColumnsCheck: new Map(),
      allMembers: [],
      membersLoaded: false,
      dayTimeFilter: DayTimeValues.None,
      dayTimeKeep: false,
      cafModelsLoaded: false,
      cafModelsMap: new Map(),
      groupByChoices: groupByChoices(true),
      inscriptionsCount: 0,
      options: makeDayListOptions(),
      fieldFilter: null,
      onlyPresentMembers: true,
      individualsEntities: new Map(),
    }
  },
  watch: {
    loading: function(newValue, oldValue) {},
    groupBy: function() {
      this.hiddenSuperGroups = {}
      this.hiddenGroups = {}
      window.localStorage.setItem(groupByKey, '' + this.groupBy.id)
    },
    printMode: function() {
      window.localStorage.setItem(printModeKey, this.printMode ? '1' : '0')
    },
    showOpeningHours: function() {
      window.localStorage.setItem(hideOpeningHoursKey, this.showOpeningHours ? '0' : '1')
    },
    landscape: function() {
      window.localStorage.setItem(landscapeKey, this.landscape ? '1' : '0')
    },
    pageBreak: function() {
      window.localStorage.setItem(pageBreakKey, '' + this.pageBreak)
    },
    pageBreakChoices: function() {
      if ((this.pageBreak === 2) && (this.pageBreakChoices.length <= 2)) {
        this.pageBreak = 1
      }
    },
    showSeancesDetail: function() {
      window.localStorage.setItem(showSeancesDetailKey, this.showSeancesDetail ? '1' : '0')
    },
    showAbsence: function() {
      window.localStorage.setItem(showAbsenceKey, this.showAbsence ? '1' : '0')
    },
    showParentContact: function() {
      window.localStorage.setItem(showParentContactKey, this.showParentContact ? '1' : '0')
    },
    showDayTimes: function() {
      window.localStorage.setItem(showDayTimesKey, this.showDayTimes ? '1' : '0')
    },
    showDuration: function() {
      if (this.showDuration) {
        this.loadCafModels()
      }
    },
  },
  computed: {
    title() {
      return 'Listing journée ' + dateToString(this.seanceDate, 'DD/MM/YYYY') + ' ' + this.youthHomeElt.name
    },
    canBreakPage() {
      if (this.groupBy && (this.groupBy.id === GroupByChoice.AllInOneGroup)) {
        return false
      } else {
        return true
      }
    },
    showSchoolClass() {
      return (this.groupBy && (this.groupBy.id === GroupByChoice.School))
    },
    schoolClassesMap() {
      let schoolClassesMap = new Map()
      for (const schoolClass of this.schoolClasses) {
        schoolClassesMap.set(schoolClass.id, schoolClass)
      }
      return schoolClassesMap
    },
    quickInscriptionsModal() {
      return (this.seanceTypeElt.multiInscriptionModal === MultiInscriptionsModalChoices.QUICK)
    },
    pageBreakChoices() {
      const choices = [makeChoice({ id: 0, name: 'Pas de saut de page', })]
      if (this.groupBy && (this.groupBy.id === GroupByChoice.SchoolAndClass)) {
        choices.push(makeChoice({ id: 1, name: 'Saut de page par école', }))
        choices.push(makeChoice({ id: 2, name: 'Saut de page par classe', }))
      } else if (this.groupBy && (this.groupBy.id === GroupByChoice.SchoolLevel)) {
        choices.push(makeChoice({ id: 1, name: 'Saut de page par niveau scolaire', }))
      } else if (this.groupBy && (this.groupBy.id === GroupByChoice.AllInOneGroup)) {
      } else {
        // by default AgeGroup
        choices.push(makeChoice({ id: 1, name: 'Saut de page par groupe d\'âge', }))
      }
      return choices
    },
    youthHomeLabel() {
      return store.getters.youthHomeLabel
    },
    clockingColumns() {
      if (this.youthHomeElt.overrideClockingColumns) {
        return this.youthHomeElt.clockingColumns
      } else {
        return this.seanceTypeElt.clockingColumns
      }
    },
    youthHomeIcon() {
      return store.getters.youthHomeIcon
    },
    statusFilters() {
      if (this.hasOpeningHours) {
        return [0, 1, 5, 2, 3, 4].map(
          index => makeChoice({ id: index, name: this.getStatusLabel(index), })
        )
      }
      return []
    },
    counterFields() {
      return this.fields.filter(elt => elt.showCounter)
    },
    markFields() {
      return this.fields.filter(elt => elt.showAsMark)
    },
    colFields() {
      if (this.printMode) {
        return this.fields.filter(elt => elt.showInCol && (!elt.showAsMark || elt.showAsMarkAndText))
      } else {
        return []
      }
    },
    rowFields() {
      let fields = this.fields.filter(elt => (!elt.showAsMark || elt.showAsMarkAndText))
      if (this.printMode) {
        return fields.filter(elt => !elt.showInCol)
      } else {
        return fields
      }
    },
    clockingSeance() {
      return this.seanceTypeElt.clockingSeance
    },
    hasEvenings() {
      return this.seances.filter(elt => elt.evening).length > 0
    },
    hasLunch() {
      const hasLunch = this.seances.filter(elt => elt.lunch && !elt.picnic).length > 0
      const hasPicnic = this.seances.filter(elt => elt.lunch && elt.picnic).length > 0
      return hasLunch || !hasPicnic
    },
    hasPicnic() {
      return this.seances.filter(elt => elt.lunch && elt.picnic).length > 0
    },
    canSwitch() {
      return (this.youthHomeElt.allowSwitchInscription) && this.hasPerm('youth.add_seanceinscription')
    },
    showAllMembers() {
      return (this.youthHomeElt.showAllMembersOnDailyList)
    },
    showMain() {
      return !(this.showEmails || this.showMobiles)
    },
    isToday() {
      return this.seanceDateAsString === moment().format('YYYY-MM-DD')
    },
    seanceDate() {
      return moment(this.seanceDateAsString, 'YYYY-MM-DD').toDate()
    },
    hasSelectedSeances() {
      return (this.selectedSeances.length > 0) & (this.selectedSeances.length < this.seances.length)
    },
    absencesCount() {
      return this.elements.filter(elt => this.isAbsence(elt)).length
    },
    presencesCount() {
      return this.elements.filter(elt => !this.isAbsence(elt)).length
    },
    filteredElements() {
      let elements = []
      if (this.selectedSeances.length === 0) {
        elements = this.filteredByWorkshops
      } else {
        elements = this.filteredByWorkshops.filter(
          elt => { return this.doesInscriptionMatch(elt) }
        )
      }
      if (this.nameFilter) {
        elements = elements.filter(
          elt => {
            return includes(elt.individual.lastAndFirstName(), this.nameFilter)
          }
        )
      }
      if (this.showAllMembers && this.onlyPresentMembers) {
        elements = elements.filter(
          elt => {
            return !this.hasAbsence(elt)
          }
        )
      }
      if (this.fieldFilter) {
        elements = elements.filter(
          elt => this.matchFieldFilter(elt)
        )
      }
      if (this.statusFilter) {
        if (this.statusFilter === 1) {
          // Présents
          elements = elements.filter(
            elt => {
              return !this.hasAbsence(elt)
            }
          )
        } else if (this.statusFilter === 2) {
          // Absents
          elements = elements.filter(
            elt => {
              return this.hasAbsence(elt)
            }
          )
        } else if (this.statusFilter === 3) {
          // Encore Présents
          elements = elements.filter(
            elt => {
              return this.isStillHere(elt)
            }
          )
        } else if (this.statusFilter === 4) {
          // Partis
          elements = elements.filter(
            elt => {
              return this.isClockingDone(elt)
            }
          )
        } else if (this.statusFilter === 5) {
          // Pas encore arrivés
          elements = elements.filter(
            elt => {
              return this.notArrived(elt)
            }
          )
        }
      }
      if (this.showBirthdays) {
        elements = elements.filter(
          elt => isBirthday(elt.individual.birthDate, this.day)
        )
      }
      if (this.dayTimeFilter !== DayTimeValues.None) {
        elements = elements.filter(
          elt => this.filterDayTime(this.dayTimeFilter, this.dayTimeKeep, elt)
        )
      }
      return elements
    },
    filteredByWorkshops() {
      if (this.selectedWorkshops.length === 0) {
        return this.elements
      } else {
        return this.elements.filter(
          elt => {
            let seances = elt.inscriptions.map(ins => ins.seance)
            for (const seance of seances) {
              let workshopIds = seance.getWorkshopInscriptions(elt.individual.id).map(ins => ins.workshop)
              if (this.doesWorkshopMatch(workshopIds)) {
                return true
              }
            }
            return false
          }
        )
      }
    },
    elementsByGroups() {
      if (this.groupBy && (this.groupBy.id === GroupByChoice.SchoolAndClass)) {
        return this.elementsBySchoolClass
      } else if (this.groupBy && (this.groupBy.id === GroupByChoice.SchoolLevel)) {
        return this.elementsBySchoolLevelGroups
      } else if (this.groupBy && (this.groupBy.id === GroupByChoice.AllInOneGroup)) {
        return this.allElementsInOneGroup
      } else if (this.groupBy && (this.groupBy.id === GroupByChoice.School)) {
        return this.elementsBySchool
      } else {
        // by default AgeGroup
        return this.elementsByAgeGroups
      }
    },
    elementsByAgeGroups() {
      if (this.isLoading(this.loadingName)) {
        return []
      }
      let ageGroups = this.ageGroups.map(elt => {
        return {
          name: elt.name,
          elements: [],
          startAge: elt.startAge,
          endAge: elt.endAge,
          id: elt.startAge,
          limits: this.getAgeGroupLimits(elt),
          showHeader: true,
          cssClass: 'main',
        }
      })
      ageGroups.push(
        {
          name: ageGroups.length ? 'Autres' : 'Tous',
          elements: [],
          startAge: 0,
          endAge: 0,
          id: 0,
          limits: [],
          cssClass: 'main',
        }
      )
      let ageGroupsIndex = {}
      for (let index = 0; index < ageGroups.length; index++) {
        let ageGroup = ageGroups[index]
        if (ageGroup.startAge) {
          const endAge = ageGroup.endAge ? ageGroup.endAge : 18
          for (let age = ageGroup.startAge; age <= endAge; age++) {
            ageGroupsIndex[age] = index
          }
        }
      }
      for (let element of this.filteredElements) {
        let age = this.getAgeGroup(element)
        let index = ageGroupsIndex[age]
        if (isNaN(index)) {
          index = -1
        }
        if ((index >= 0) && (index < ageGroups.length)) {
          ageGroups[index].elements.push(element)
        } else {
          ageGroups[ageGroups.length - 1].elements.push(element)
        }
      }
      return [
        { id: 1, name: '', groups: ageGroups, showHeader: false, }
      ]
    },
    elementsBySchoolLevelGroups() {
      if (this.isLoading(this.loadingName)) {
        return []
      }
      let schoolLevelGroups = this.schoolLevelGroups.map(elt => {
        return {
          name: elt.name,
          elements: [],
          id: elt.id,
          showHeader: true,
          limits: [],
          cssClass: 'main',
        }
      })
      const notDefinedGroup = {
        name: 'Non défini',
        elements: [],
        id: 0,
        showHeader: true,
        limits: [],
        cssClass: 'main',
      }
      schoolLevelGroups.push(notDefinedGroup)
      let schoolLevelGroupsIndex = {}
      for (let index = 0; index < schoolLevelGroups.length; index++) {
        let schoolLevelGroup = schoolLevelGroups[index]
        schoolLevelGroupsIndex[schoolLevelGroup.id] = index
      }
      let hasNotSet = false
      for (let element of this.filteredElements) {
        let schoolLevelGroupId = element.child.schoolLevelGroup ? element.child.schoolLevelGroup.id : 0
        let index = schoolLevelGroupsIndex[schoolLevelGroupId]
        if (isNaN(index)) {
          index = -1
        }
        if ((index >= 0) && (index < schoolLevelGroups.length)) {
          schoolLevelGroups[index].elements.push(element)
        } else {
          notDefinedGroup.elements.push(element)
        }
      }
      return [
        { id: 1, name: '', groups: schoolLevelGroups, showHeader: false, }
      ]
    },
    allElementsInOneGroup() {
      if (this.isLoading(this.loadingName)) {
        return []
      }
      const mainGroup = {
        name: 'Tous',
        elements: this.filteredElements,
        id: 1,
        showHeader: true,
        limits: [],
        cssClass: 'main',
      }
      return [
        { id: 1, name: '', groups: [mainGroup], showHeader: false, }
      ]
    },
    elementsBySchoolClass() {
      if (this.isLoading(this.loadingName)) {
        return []
      }
      let schoolClassMap = {}
      for (let schoolClass of this.schoolClasses) {
        schoolClassMap[schoolClass.id] = schoolClass.school.id
      }
      let schools = [
        {
          id: 0,
          name: 'École non définie',
          groups: [
            { name: '', elements: [], id: 0, limits: [], showHeader: true, }
          ],
          showHeader: false,
        }
      ]
      let schoolClasses = [].concat(this.schoolClasses)
      schoolClasses = schoolClasses.sort(
        (schoolClass1, schoolClass2) => {
          if (schoolClass1.school.order > schoolClass2.school.order) {
            return 1
          } else if (schoolClass1.school.order < schoolClass2.school.order) {
            return -1
          } else {
            if (schoolClass1.school.name > schoolClass2.school.name) {
              return 1
            } else if (schoolClass1.school.name < schoolClass2.school.name) {
              return -1
            } else {
              if (schoolClass1.order > schoolClass2.order) {
                return 1
              } else if (schoolClass1.order < schoolClass2.order) {
                return -1
              } else {
                if (schoolClass1.name > schoolClass2.name) {
                  return 1
                } else if (schoolClass1.name < schoolClass2.name) {
                  return -1
                } else {
                  return 0
                }
              }
            }
          }
        }
      ).map(
        elt => {
          return { name: elt.fullName(), elements: [], id: elt.id, limits: [], parent: elt.school, showHeader: true, }
        }
      )
      let schoolGroupsIndex = { '0#0': [0, 0], }
      for (let index = 0; index < schoolClasses.length; index++) {
        let schoolClass = schoolClasses[index]
        let schoolClassIndex = 0
        let schoolIndex = schools.map(elt => elt.id).indexOf(schoolClass.parent ? schoolClass.parent.id : 0)
        if (schoolIndex < 0) {
          schools.push(
            {
              id: schoolClass.parent ? schoolClass.parent.id : 0,
              name: schoolClass.parent ? schoolClass.parent.name : '',
              groups: [schoolClass],
              showHeader: true,
            }
          )
          schoolIndex = schools.length - 1
          schoolClassIndex = 0
        } else {
          schools[schoolIndex].groups.push(schoolClass)
          schoolClassIndex = schools[schoolIndex].groups.length - 1
        }
        const key = '' + schoolClass.parent.id + '#' + schoolClass.id
        schoolGroupsIndex[key] = [schoolIndex, schoolClassIndex]
      }
      for (let element of this.filteredElements) {
        let schoolClassIndex = 0
        let schoolIndex = 0
        if (element.child.schoolClass) {
          const schoolClassId = element.child.schoolClass.id
          let schoolId = 0
          if (schoolClassId > 0) {
            schoolId = schoolClassMap[schoolClassId]
          }
          const key = '' + schoolId + '#' + schoolClassId
          schoolIndex = schoolGroupsIndex[key][0]
          schoolClassIndex = schoolGroupsIndex[key][1]
        }
        if ((isNaN(schoolIndex)) || (schoolIndex < 0) || (schoolIndex > schools.length)) {
          schoolIndex = 0
        }
        let schoolClasses = schools[schoolIndex].groups
        if ((isNaN(schoolClassIndex)) || (schoolClassIndex < 0) || (schoolClassIndex > schoolClasses.length)) {
          schoolClassIndex = 0
          schoolIndex = 0
          schoolClasses = schools[schoolIndex]
        }
        schoolClasses[schoolClassIndex].elements.push(element)
      }
      return schools.filter(
        elt => !((elt.id === 0) && elt.groups.length && elt.groups[0].elements.length === 0)
      )
    },
    elementsBySchool() {
      if (this.isLoading(this.loadingName)) {
        return []
      }
      let schools = [
        {
          id: 0,
          name: 'École non définie',
          elements: [],
          showHeader: false,
        }
      ]
      schools = schools.concat(
        distinct(
          this.schoolClasses.map(elt => elt.school)
        ).sort(
          (school1, school2) => {
            if (school1.order > school2.order) {
              return 1
            } else if (school1.order < school2.order) {
              return -1
            } else {
              if (school1.name > school2.name) {
                return 1
              } else if (school1.name < school2.name) {
                return -1
              } else {
                return 0
              }
            }
          }
        )
      )
      schools = schools.map(
        elt => {
          return {
            name: elt.name,
            elements: [],
            id: elt.id,
            limits: [],
            showHeader: false,
          }
        }
      )
      const schoolIds = schools.map(elt => elt.id)
      const schoolClassMap = new Map()
      for (const schoolClass of this.schoolClasses) {
        let index = schoolIds.indexOf(schoolClass.school.id)
        schoolClassMap.set(schoolClass.id, index)
      }
      for (let element of this.filteredElements) {
        let schoolIndex = 0
        if (element.child.schoolClass) {
          const schoolClassId = element.child.schoolClass.id
          let schoolId = 0
          if (schoolClassId > 0) {
            schoolIndex = schoolClassMap.get(schoolClassId)
          }
        }
        if ((isNaN(schoolIndex)) || (schoolIndex < 0) || (schoolIndex > schools.length)) {
          schoolIndex = 0
        }
        schools[schoolIndex].elements.push(element)
      }
      schools = schools.filter(
        elt => elt.elements.length
      )
      return [
        { id: 1, name: '', groups: schools, showHeader: false, }
      ]
    },
    individualIds() {
      return this.elements.map(elt => elt.individual.id)
    },
    getExtraLinks() {
      const links = []
      if (this.showMain) {
        links.push(
          {
            id: 4,
            label: 'Email',
            callback: this.sendEmails,
            icon: 'fa fa-send',
            cssClass: this.isLoading(this.loadingName) ? 'btn-secondary disabled' : 'btn-secondary',
          }
        )
        links.push(
          {
            id: 5,
            label: 'SMS',
            callback: this.sendSms,
            icon: 'fas fa-mobile-alt',
            cssClass: this.isLoading(this.loadingName) ? 'btn-secondary disabled' : 'btn-secondary',
          }
        )
        links.push(
          {
            id: 6,
            label: 'Excel',
            callback: this.excelMe,
            icon: 'fa fa-file-excel',
            cssClass: this.isLoading(this.loadingName) ? 'btn-secondary disabled' : 'btn-secondary',
          }
        )
        links.push(
          {
            id: 7,
            label: 'Modifier les horaires',
            callback: this.changeOpeningHours,
            icon: 'fa fa-clock',
            cssClass: this.isLoading(this.loadingName) ? 'btn-secondary disabled' : 'btn-secondary',
          }
        )
      }
      return links
    },
    getLinks() {
      const links = []
      if (!this.showMain) {
        links.push(
          {
            id: 99,
            label: 'Retour',
            callback: this.resetMain,
            icon: 'fa fa-chevron-left',
            cssClass: this.isLoading(this.loadingName) ? 'btn-secondary disabled' : 'btn-secondary',
          }
        )
      } else {
        links.push(
          {
            id: 1,
            label: 'Pdf',
            callback: this.printMe,
            icon: 'fa fa-file-pdf',
            cssClass: this.isLoading(this.loadingName) ? 'btn-secondary disabled' : 'btn-secondary',
          }
        )
        if (this.hasPerm('documents.view_individuallinkdocument')) {
          links.push(
            {
              id: 2,
              label: 'Fiches Liaison',
              callback: this.loadLinkDocuments,
              icon: 'fa fa-download',
              cssClass: this.isLoading(this.loadingName) ? 'btn-secondary disabled' : 'btn-secondary',
            }
          )
        }
        // const canAdd = this.updatedInscriptions.length > 0 && !this.isLoading(this.loadingName)
        if (this.hasPerm('youth.add_seanceinscription')) {
          links.push(
            {
              id: 3,
              label: 'Inscrire',
              callback: this.showMulti,
              icon: 'fa fa-person-circle-plus',
              cssClass: this.isLoading(this.loadingName) ? 'btn-primary disabled' : 'btn-primary',
            }
          )
        }
      }
      return links
    },
    hasOpeningHours() {
      if (this.openingHours) {
        return !!(
          this.openingHours.openingAt ||
          this.openingHours.closingAt ||
          this.openingHours.openingAt2 ||
          this.openingHours.closingAt2 ||
          this.openingHours.openingAt3 ||
          this.openingHours.closingAt3
        )
      }
      return false
    },
    emails() {
      let emails = []
      for (let element of this.filteredElements) {
        for (let parent of element.parents) {
          if (parent.email && (emails.indexOf(parent.email) < 0)) {
            emails.push(parent.email)
          }
        }
      }
      return emails
    },
    mobiles() {
      let mobiles = []
      for (let element of this.filteredElements) {
        for (let parent of element.parents) {
          if (parent.cellPhone && (mobiles.indexOf(parent.cellPhone) < 0)) {
            mobiles.push(parent.cellPhoneForSms())
          }
        }
      }
      return mobiles
    },
    children() {
      return this.filteredElements.map(
        element => {
          return {
            individual: element.individual,
            parent1: element.parents.length ? element.parents[0] : null,
            parent2: (element.parents.length > 1) ? element.parents[1] : null,
          }
        }
      )
    },
    emailsGroups() {
      return emailsGrouper(this.emails)
    },
    birthdayCount() {
      return this.filteredElements.filter(
        elt => isBirthday(elt.individual.birthDate, this.day)
      ).length
    },
    updatedInscriptions() {
      const updated = []
      for (const elt of this.elements) {
        for (const seance of elt.entitySeances) {
          if (seance.hasNewInscriptions() || seance.hasCancellations()) {
            updated.push(seance)
          }
        }
      }
      return updated
    },
    updatedInscriptionCount() {
      let count = 0
      for (const elt of this.elements) {
        let eltCount = elt.entitySeances.filter(
          seance => seance.hasNewInscriptions() || seance.hasCancellations()
        ).length ? 1 : 0
        count += eltCount
      }
      return count
    },
    lastCol() {
      if (this.printMode && !this.colFields.length) {
        return this.clockingColumns.length ? 5 : 6
      } else {
        return 3
      }
    },
    hasPreInscriptions() {
      return this.seanceColumns.filter(seance => seance.duration === 0).length
    },
    dayTimeLabels() {
      return this.youthHomeElt.dayTimeLabels
    },
    dayTimeLetters() {
      return this.youthHomeElt.dayTimeLetters
    },
  },
  mounted() {
    this.seanceDateAsString = this.day || dateToString(moment().toDate(), 'YYYY-MM-DD')
    const groupById = +(window.localStorage.getItem(groupByKey) || 0)
    const selected = this.groupByChoices.filter(elt => elt.id === groupById)
    if (selected.length) {
      this.groupBy = selected[0]
    } else if (this.groupByChoices.length) {
      this.groupBy = this.groupByChoices[0]
    }
    this.clockStatus = 0
    this.onLoaded()
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    ...mapMutations(['startLoading', 'endLoading']),
    getAgeGroup(element) {
      let ageGroups = distinct(element.inscriptions.map(elt => elt.ageGroup).filter(elt => elt !== null))
      if (ageGroups.length !== 1) {
        return getAgeGroup(element.individual, element.child, this.seanceDate, this.youthHomeElt)
      }
      return ageGroups[0].startAge
    },
    changeOpeningHours() {
      this.$bvModal.show('bv-opening-hours-modal')
    },
    resetMain() {
      this.showEmails = false
      this.showMobiles = false
    },
    async onLoaded() {
      this.showBirthDate = this.getShowBirthDate()
      this.showDuration = this.getShowDuration()
      this.showAge = this.getShowAge()
      await this.loadPresences()
      await this.loadInscriptions()
      await this.loadInscriptionRules()
    },
    getShowBirthDate() {
      return (window.localStorage.getItem('showBirthDate') || 'N') === 'O'
    },
    getShowDuration() {
      if (this.seanceTypeElt.showDurationOnListing) {
        return (window.localStorage.getItem('listingShowDuration') || 'N') === 'O'
      }
      return false
    },
    getShowAge() {
      return (window.localStorage.getItem('showAge') || 'O') === 'O'
    },
    onMultiInscriptionsClosed() {
      this.onLoaded()
    },
    onDailyInscriptionsClosed() {
      this.inscriptionElement = null
      this.inscriptionElements = []
    },
    onDailyInscriptionsSaved() {
      this.onLoaded()
    },
    onDailyInscriptionsPayClosed() {
      this.inscriptionElement = null
      this.inscriptionElements = []
      this.onLoaded()
    },
    getLinkToEntity(elt) {
      return {
        name: 'families-detail',
        params: {
          entityId: '' + elt.entity.id,
        },
        query: {
          individual: '' + elt.individual.id,
        },
      }
    },
    async loadPresences() {
      this.startLoading(this.loadingName)
      let dateAsTr = this.seanceDateAsString
      let url = '/api/youth/daily-presence/' + dateAsTr + '/' + this.youthHome + '/' + this.seanceType + '/'
      url += this.seancePeriod + '/'
      const backendApi = new BackendApi('get', url)
      try {
        const resp = await backendApi.callApi()
        const presencesMap = new Map()
        for (const presence of resp.data) {
          presencesMap.set(presence.individual, [presence.morning, presence.lunch, presence.afternoon, presence.evening])
        }
        this.presencesMap = presencesMap
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
      this.endLoading(this.loadingName)
    },
    doesInscriptionMatch(elt) {
      for (let seance of this.selectedSeances) {
        const entitySeances = elt.entitySeances.filter(es => es.id === seance.id)
        for (const es of entitySeances) {
          if (es.doesIndividualHaveInscription(elt.individual.id)) {
            return true
          }
        }
      }
      return false
    },
    doesWorkshopMatch(workshopIds) {
      for (let workshop of this.selectedWorkshops) {
        if (workshopIds.indexOf(workshop.id) >= 0) {
          return true
        }
      }
      return false
    },
    getIndividualEntities(individual) {
      if (this.individualsEntities.has(individual.id)) {
        const entities = this.individualsEntities.get(individual.id)
        if (entities.length > 1) {
          return entities
        }
      }
      return []
    },
    async loadInscriptions() {
      this.startLoading(this.loadingName)
      let dateAsTr = this.seanceDateAsString
      let url = '/api/youth/youth-homes-day/' + dateAsTr + '/' + this.youthHome + '/' + this.seanceType +
        '/' + this.seancePeriod + '/'
      const backendApi = new BackendApi('get', url)
      try {
        this.seances = []
        this.workshops = []
        let seanceIds = []
        let workshopIds = []
        const resp = await backendApi.callApi()
        this.fields = resp.data.fields.map(elt => makeDailyListField(elt))
        this.ageGroups = resp.data['age_groups'].map(elt => makeAgeGroup(elt))
        this.schoolClasses = resp.data['school_classes'].map(elt => makeSchoolClass(elt))
        this.schoolLevelGroups = resp.data['school_level_groups'].map(elt => makeSchoolLevelGroup(elt))
        this.youthHomeElt = makeYouthHome(resp.data['youth_home'])
        const dayKey = this.seanceDateAsString
        this.openingHours = makeOpeningHours(resp.data['opening_hours'][dayKey])
        this.seanceTypeElt = makeSeanceType(resp.data['seance_type'])
        this.seancePeriodElt = makeSeancePeriod(resp.data['seance_period'])
        this.seanceLimits = makeSeanceLimits(resp.data['seance_limits'])
        this.options = makeDayListOptions(resp.data.options)
        this.inscriptionsCount = resp.data['inscriptions_count'] || 0
        this.groupByChoices = groupByChoices(this.schoolLevelGroups.length > 0)
        const individualsEntities = resp.data['individuals_entities'] || []
        const individualsEntitiesMap = new Map()
        for (const individualsEntity of individualsEntities) {
          const indId = individualsEntity.individual
          const entity = individualsEntity.entity
          let listOfEntities = []
          if (individualsEntitiesMap.has(indId)) {
            listOfEntities = individualsEntitiesMap.get(indId)
          }
          listOfEntities.push(entity)
          individualsEntitiesMap.set(indId, listOfEntities)
        }
        this.individualsEntities = individualsEntitiesMap
        this.seances = resp.data['seances'].map(
          elt => makeSeance(elt)
        ).sort(
          function(seance1, seance2) {
            const value = compareNumbers(seance1.sortNumber(), seance2.sortNumber())
            if (value) {
              return value
            } else {
              const duration1 = seance1.duration
              const duration2 = seance2.duration
              if (duration1 === duration2) {
                const code1 = seance1.getCodeName()
                const code2 = seance2.getCodeName()
                return (code1 === code2) ? 0 : ((code1 > code2) ? 1 : -1)
              } else {
                return duration1 > duration2 ? 1 : -1
              }
            }
          }
        )
        this.elements = resp.data.elements.map(
          elt => {
            let individual = makeIndividual(elt['individual'])
            let child = makeChild(elt['child'])
            let inscriptions = elt['inscriptions'].map(
              elt2 => {
                elt2['individual'] = individual
                return makeSeanceInscription(elt2)
              }
            ).sort(
              function(ins1, ins2) {
                return compareNumbers(ins1.seance.sortNumber(), ins2.seance.sortNumber())
              }
            )
            let entity = null
            if (inscriptions.length) {
              entity = inscriptions[0].entity
            } else {
              const indEntities = individualsEntitiesMap.get(individual.id)
              if (indEntities && indEntities.length) {
                entity = indEntities[0]
              } else {
                entity = null
              }
            }
            let parents = inscriptions.reduce(
              (allParents, inscription) => {
                return allParents.concat(inscription.parents)
              },
              []
            )
            let inscriptionsSeances = inscriptions.map(elt => elt.seance)
            this.workshops = this.workshops.concat(
              inscriptionsSeances.reduce(
                (workshops, seance) => {
                  for (let workshop of seance.workshops) {
                    if (workshopIds.indexOf(workshop.id) < 0) {
                      workshopIds.push(workshop.id)
                      workshops.push(workshop)
                    }
                  }
                  return workshops
                },
                []
              )
            )
            return {
              individual: individual,
              entity: entity,
              inscriptions: inscriptions,
              parents: distinct(parents),
              fieldTexts: elt['field_texts'],
              fieldValues: elt['field_values'],
              fieldComments: elt['field_comments'],
              child: child,
              entitySeances: inscriptions.map(ins => ins.seance),
            }
          }
        )
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
      this.endLoading(this.loadingName)
    },
    toggleGroup(grouper) {
      if (!(grouper.id in this.hiddenGroups)) {
        this.hiddenGroups[grouper.id] = true
      } else {
        this.hiddenGroups[grouper.id] = !this.hiddenGroups[grouper.id]
      }
      this.hiddenGroups = { ...this.hiddenGroups, }
    },
    toggleSuperGroup(superGrouper) {
      if (!(superGrouper.id in this.hiddenSuperGroups)) {
        this.hiddenSuperGroups[superGrouper.id] = true
      } else {
        this.hiddenSuperGroups[superGrouper.id] = !this.hiddenSuperGroups[superGrouper.id]
      }
      this.hiddenSuperGroups = { ...this.hiddenSuperGroups, }
    },
    getSuperGrouperElements(superGrouper) {
      let elements = []
      for (let group of superGrouper.groups) {
        elements = elements.concat(group.elements)
      }
      return elements
    },
    async printMe() {
      this.printing = true
      this.printDate = dateToString(moment().toDate(), 'DD/MM/YYYY')
      const that = this
      setTimeout(
        async function() {
          const docUrl = '/documents/standard/<key>/pdf/?' + (
            that.pageBreak ? 'pageBreak=1' : ''
          ) + (
            that.landscape ? '&landscape=1' : ''
          )
          const docSlug = that.seanceDateAsString + '-' + slugify(that.youthHomeElt.name)
          const docContent = that.$refs.printMe.innerHTML.toString()
          try {
            await openDocument(docUrl, docSlug, docContent)
          } catch (err) {
            await that.addError(this.getErrorText(err))
          }
          that.printing = false
          that.printDate = ''
        },
        200
      )
    },
    getSeanceLimit(seance, ageGroup) {
      if (this.seanceLimits) {
        return this.seanceLimits.getSeanceLimit(seance, ageGroup)
      }
      return null
    },
    getAgeForGroup(individual) {
      return getAgeForGroup(individual, this.seanceDate, this.youthHomeElt)
    },
    showIndividualBirthday(elt) {
      return isBirthday(elt.individual.birthDate, this.day)
    },
    getSchoolClassName(elt) {
      let schoolClassId = elt.child.schoolClass.id
      if (this.schoolClassesMap.has(schoolClassId)) {
        return this.schoolClassesMap.get(schoolClassId).name
      }
      return ''
    },
    hasCustomGroup(elt) {
      return !!getCustomAge(elt.child)
    },
    getCustomGroup(elt) {
      return getCustomAge(elt.child)
    },
    getAgeGroupLimits(ageGroup) {
      if (this.seanceLimits) {
        return this.seanceLimits.getAgeGroupLimits(ageGroup)
      }
      return []
    },
    getDailyLimits() {
      if (this.seanceLimits) {
        return this.seanceLimits.getDailyLimits()
      }
      return []
    },
    toggleClockSelector() {
      this.clockStatus += 1
      if (this.clockStatus > 2) {
        this.clockStatus = 0
      }
    },
    async loadLinkDocuments() {
      try {
        let resp = await loadLinkDocuments(this.seanceDateAsString, this.elements)
        this.showDocInfo = true
        this.docsSavedOn = resp.docsSavedOn
        this.missingDocIds = resp.missingDocIds
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    showIndividualLinkDocMissing(elt) {
      const docKey = '' + elt.individual.id + '-' + elt.entity.id
      return (this.missingDocIds.indexOf(docKey) >= 0)
    },
    showIndividualLinkDocOutdated(elt) {
      const docKey = '' + elt.individual.id + '-' + elt.entity.id
      if (this.docsSavedOn.hasOwnProperty(docKey)) {
        const docDate = moment(this.docsSavedOn[docKey])
        if (docDate < moment(elt.individual.updatedOn)) {
          return true
        }
      }
      return false
    },
    async showMulti() {
      this.multiInscriptionLoaded = true
      const multiModalId = this.quickInscriptionsModal
        ? 'bv-modal-multi-inscriptions-quick'
        : 'bv-modal-multi-inscriptions'
      setTimeout(
        () => { this.$bvModal.show(multiModalId) },
        200
      )
      await this.loadAllMembers()
    },
    refreshInscription(element, updatedInscription) {
      const index = element.inscriptions.map(elt => elt.id).indexOf(updatedInscription.id)
      if (index >= 0) {
        element.inscriptions[index] = updatedInscription
      }
      this.elements = [].concat(this.elements)
    },
    getSeanceName(elt) {
      return elt.getShortName()
    },
    grouperCounter(grouper) {
      return grouper.elements.filter(elt => !this.isAbsence(elt)).length
    },
    getWorkshopInscriptions(inscription, individual) {
      return inscription.seance.getWorkshopInscriptions(individual.id)
    },
    hasAbsence(elt) {
      return elt.inscriptions.filter(
        inscription => ((inscription.absence) || (inscription.cancelledOn))
      ).length === elt.inscriptions.length
    },
    isAbsence(elt) {
      return elt.inscriptions.filter(
        inscription => !(inscription.absence || inscription.cancelledOn)
      ).length === 0
    },
    isClockingDone(elt) {
      return elt.inscriptions.filter(inscription => !inscription.isClockingDone(this.openingHours)).length === 0
    },
    notArrived(elt) {
      if (elt.inscriptions.filter(ins => ins.clockingInProgress).length > 0) {
        return true
      }
      if (this.isAbsence(elt)) {
        return false
      }
      if (this.isStillHere(elt)) {
        return false
      }
      return elt.inscriptions.filter(inscription => inscription.isNotArrived(this.openingHours)).length > 0
    },
    isStillHere(elt) {
      return elt.inscriptions.filter(inscription => inscription.isHere(this.openingHours)).length > 0
    },
    sendEmails() {
      this.showEmails = true
    },
    sendSms() {
      this.showMobiles = true
    },
    async excelMe() {
      const docUrl = '/documents/table-to-excel/<key>/'
      const docSlug = this.seanceDateAsString + '-' + slugify(this.youthHomeElt.name)
      const docContent = this.$refs.excelTable.innerHTML.toString()
      try {
        await openDocument(docUrl, docSlug, docContent)
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    getSuperGrouperGroups(superGrouper) {
      return superGrouper.groups.filter(group => group.elements.length > 0)
    },
    onSeanceClicked(event) {
      if (this.canSwitch) {
        this.selectedElement = event.element
        this.selectedInscription = event.inscription
        this.$bvModal.show('switch-seance-inscription-modal')
      }
    },
    onChangeInscriptions(element) {
      if (this.updatedInscriptions.length === 0) {
        if (this.youthHomeElt.payOnDaily) {
          const familyElements = this.elements.filter(
            current => current.entity.id === element.entity.id
          )
          this.inscriptionElements = familyElements
          this.$bvModal.show('bv-modal-daily-inscriptions-and-pay')
        } else {
          this.inscriptionElement = element
          this.$bvModal.show('bv-modal-daily-inscriptions')
        }
      }
    },
    onRefreshed(event) {
      const individual = event.individual
      const newInscription = event.inscription
      const replaced = event.replaced || null
      const index1 = this.elements.map(elt => elt.individual.id).indexOf(individual.id)
      if (index1 >= 0) {
        const element = this.elements[index1]
        const index2 = element.inscriptions.map(elt => elt.id).indexOf(newInscription.id)
        if (index2 >= 0) {
          element.inscriptions[index2] = newInscription
        } else {
          element.inscriptions.push(newInscription)
        }
        if (replaced) {
          const index3 = element.inscriptions.map(elt => elt.id).indexOf(replaced.id)
          if (index3 >= 0) {
            element.inscriptions.splice(index3, 1)
          }
        }
        this.elements[index1] = { ...element, }
      }
      this.elements = [].concat(this.elements)
    },
    getStatusLabel(statusFilter) {
      switch (statusFilter) {
        case 0:
          return 'Tous'
        case 1:
          return 'Présents'
        case 2:
          return 'Absents'
        case 3:
          return 'Encore présents'
        case 4:
          return 'Pointage complet'
        case 5:
          return 'Pas encore arrivé'
      }
      return '????'
    },
    getEltName() {
      return [this.youthHomeElt.name, this.seanceTypeElt.name, this.seancePeriodElt.name].map(
        itm => itm.trim()
      ).filter(
        itm => (itm && itm !== '-')
      ).join(' - ')
    },
    toggleDuration() {
      this.showDuration = !this.showDuration
      window.localStorage.setItem('listingShowDuration', this.showDuration ? 'O' : 'N')
    },
    toggleBirthDate() {
      if (this.showAge && this.showBirthDate) {
        this.showBirthDate = false
      } else {
        if (this.showAge) {
          this.showAge = false
          this.showBirthDate = true
        } else {
          this.showAge = true
        }
      }
      if (!this.showAge && !this.showBirthDate) {
        this.showAge = true
      }
      window.localStorage.setItem('showBirthDate', this.showBirthDate ? 'O' : 'N')
      window.localStorage.setItem('showAge', this.showAge ? 'O' : 'N')
    },
    async loadInscriptionRules() {
      if (!this.rulesLoaded) {
        let url = '/api/youth/inscription-rules/' + this.seanceTypeElt.id + '/' + this.youthHomeElt.id + '/'
        let backendApi = new BackendApi('get', url)
        try {
          let resp = await backendApi.callApi()
          this.rules = resp.data.map(elt => makeInscriptionRule(elt))
          this.rulesLoaded = true
        } catch (err) {
          await this.addError(this.getErrorText(err))
        }
      }
    },
    async loadCafModels() {
      if (!this.cafModelsLoaded) {
        const seances = this.seances.map(elt => '' + elt.id).join('-')
        let url = '/reports/api/seance-caf-models/?seances=' + seances
        let backendApi = new BackendApi('get', url)
        try {
          let resp = await backendApi.callApi()
          for (const elt of resp.data) {
            this.cafModelsMap.set(+elt.seance, makeCafModel(elt.model))
          }
          this.cafModelsLoaded = true
        } catch (err) {
          await this.addError(this.getErrorText(err))
        }
      }
    },
    onSeancesUpdated(elt, event) {
      this.seanceColumns = event.seances
      elt.entitySeances = event.seances
    },
    getEntityInscriptionForConfirmation() {
      const map = new Map()
      for (const elt of this.elements) {
        if (!map.has(elt.entity.id)) {
          map.set(elt.entity.id, [])
        }
        for (const seance of elt.entitySeances) {
          if (seance.hasNewInscriptions() || seance.hasCancellations()) {
            map.get(elt.entity.id).push({ individual: elt.individual, seance: seance, })
          }
        }
      }
      return map
    },
    clickClockingColumn(grouper, seance) {
      const seanceColumnsCheck = new Map(this.seanceColumnsCheck)
      for (const elt of grouper.elements) {
        seanceColumnsCheck.set(elt.individual.id, seance)
      }
      this.seanceColumnsCheck = new Map(seanceColumnsCheck)
    },
    getSeanceColumnCheck(elt) {
      if (this.seanceColumnsCheck.has(elt.individual.id)) {
        return this.seanceColumnsCheck.get(elt.individual.id)
      }
      return null
    },
    resetSeanceColumnCheck(event) {
      if (this.seanceColumnsCheck.has(event.individual.id)) {
        this.seanceColumnsCheck.delete(event.individual.id)
      }
    },
    async onConfirmUpdatedInscriptions() {
      this.startLoading(this.loadingName)
      const map = this.getEntityInscriptionForConfirmation()
      for (const entityId of map.keys()) {
        const data = []
        for (const item of map.get(entityId)) {
          const seance = item.seance
          const individual = item.individual
          if (seance.isIndividualInscriptionNew(individual.id)) {
            data.push(
              {
                seance: seance.id,
                individual: individual.id,
                cancelled: false,
              }
            )
          }
          if (seance.isIndividualInscriptionCancelled(individual.id)) {
            data.push(
              {
                seance: seance.id,
                individual: individual.id,
                cancelled: true,
                refund: true, // l'inscription annulée est remboursée.
                hide: seance.duration === 0, // une préinscription annulée ne sera pas affichée sur la fiche famille.
              }
            )
          }
        }
        if (data.length) {
          let url = '/api/youth/entity-create-inscriptions/' + entityId + '/'
          let backendApi = new BackendApi('post', url)
          try {
            await backendApi.callApi(data)
          } catch (err) {
            await this.addError(this.getErrorText(err))
          }
        }
      }
      this.endLoading(this.loadingName)
      await this.loadInscriptions()
    },
    async loadAllMembers() {
      if (!this.membersLoaded && this.youthHomeElt && this.youthHomeElt.activity) {
        this.startLoading(this.loadingName)
        try {
          const activityId = this.youthHomeElt.activity
          let url = '/api/youth/activities/' + activityId + '/inscriptions/'
          let backendApi = new BackendApi('get', url)
          let resp = await backendApi.callApi()
          const inscriptions = resp.data.inscriptions.map(elt => makeActivityInscription(elt))
          this.allMembers = inscriptions.filter(ins => !ins.cancelled).map(ins => ins.individual)
          this.membersLoaded = true
        } catch (err) {
          await this.addError(this.getErrorText(err))
        }
        this.endLoading(this.loadingName)
      }
    },
    onDayTimeSelected(event) {
      if (event.daytime) {
        this.dayTimeFilter = event.daytime
        this.dayTimeKeep = event.mode
      } else {
        this.dayTimeFilter = DayTimeValues.None
        this.dayTimeKeep = false
      }
    },
    filterDayTime(dayTimeFilter, dayTimeKeep, element) {
      const dayTimes = getInscriptionsDayTimes(element.inscriptions)
      let keep = false
      if (dayTimes.morning && dayTimeFilter === DayTimeValues.Morning) {
        keep = true
      } else if (dayTimes.lunch && dayTimeFilter === DayTimeValues.Lunch) {
        keep = true
      } else if (dayTimes.afternoon && dayTimeFilter === DayTimeValues.Afternoon) {
        keep = true
      } else if (dayTimes.evening && dayTimeFilter === DayTimeValues.Evening) {
        keep = true
      } else if (dayTimes.picnic && dayTimeFilter === DayTimeValues.Picnic) {
        keep = true
      }
      return dayTimeKeep ? keep : !keep
    },
    getCafModel(inscription) {
      return this.cafModelsMap.get(inscription.seance.id) || null
    },
    getRealDuration(elt) {
      const selectedSeanceIds = this.selectedSeances.map(elt => elt.id)
      let inscriptions = elt.inscriptions
      if (selectedSeanceIds.length && this.showOnlySelectedSeances) {
        inscriptions = inscriptions.filter(
          ins => existsIn([ins.seance.id], selectedSeanceIds)
        )
      }
      return sum(
        inscriptions.map(
          ins => calculateRealDuration(ins, this.getCafModel(ins))
        )
      )
    },
    getPaidDuration(elt) {
      const selectedSeanceIds = this.selectedSeances.map(elt => elt.id)
      let inscriptions = elt.inscriptions
      if (selectedSeanceIds.length && this.showOnlySelectedSeances) {
        inscriptions = inscriptions.filter(
          ins => existsIn([ins.seance.id], selectedSeanceIds)
        )
      }
      return sum(
        inscriptions.map(
          ins => calculatePaidDuration(ins, this.getCafModel(ins))
        )
      )
    },
    getSumRealDuration(grouper) {
      return sum(
        grouper.elements.map(
          elt => this.getRealDuration(elt)
        )
      )
    },
    getSumPaidDuration(grouper) {
      return sum(
        grouper.elements.map(
          elt => this.getPaidDuration(elt)
        )
      )
    },
    getTotalRealDuration() {
      let total = 0
      for (const superGrouper of this.elementsByGroups) {
        total += sum(superGrouper.groups.map(grouper => this.getSumRealDuration(grouper)))
      }
      return total
    },
    getTotalPaidDuration() {
      let total = 0
      for (const superGrouper of this.elementsByGroups) {
        total += sum(superGrouper.groups.map(grouper => this.getSumPaidDuration(grouper)))
      }
      return total
    },
    matchFieldFilter(elt) {
      if (this.fieldFilter) {
        const field = this.fieldFilter
        let value = false
        if (field.field in elt.fieldValues) {
          if (field.falseOnly) {
            value = !elt.fieldValues[field.field]
          } else {
            value = !!elt.fieldValues[field.field]
          }
        }
        return value
      }
      return true
    },
    getFieldCounter(field) {
      return this.elements.filter(
        elt => {
          let value = false
          if (field.field in elt.fieldValues) {
            if (field.falseOnly) {
              value = !elt.fieldValues[field.field]
            } else {
              value = !!elt.fieldValues[field.field]
            }
          }
          return value
        }
      ).length
    },
    filterField(field) {
      if (this.fieldFilter && this.fieldFilter.id === field.id) {
        this.fieldFilter = null
      } else {
        this.fieldFilter = field
      }
    },
    isFieldFiltered(field) {
      return (this.fieldFilter && this.fieldFilter.id === field.id)
    },
    setEltEntity(elt, entity) {
      elt.entity = entity
    },
  },
}
</script>

<style lang="less" scoped>
.highlight {
  font-weight: bold;
  color: #cc3700;
}

.super-grouper {
  margin-bottom: 20px;
}

.super-grouper-header {
  padding: 10px;
  background: #bbb;
  font-weight: bold;
}
.super-grouper-header a, .grouper-header a  {
  margin-left: 3px;
}
.grouper-header {
  padding: 10px 0;
  background: #ccc;
  font-weight: bold;
}
.list-line-full {
  padding: 8px 0;
}
.grouper {
  margin-bottom: 0;
}
.grouper.main {
  margin-bottom: 20px;
}
.grouper.main .grouper-header {
  background: #bbb;
}

.sub-header {
  margin-bottom: 10px;
}
.select-separator {
  margin-bottom: 5px;
}
.inscription-line {
  padding: 0 0 2px 0;
  margin-bottom: 2px;
  border-bottom: solid 1px #aaa;
}
.inscription-line:last-of-type {
  padding-bottom: 0;
  margin-bottom: 0;
  border-bottom: none;
}
.limit-warning {
  background: #e8c67c;
  padding: 2px 4px;
  color: #000;
  display: block;
  font-size: 11px;
}
.checkbox-selector {
  padding: 3px;
  border: solid 1px #ccc;
  margin: 3px 0;
}
.grouper-counter {
  display: inline-block;
  vertical-align: top;
}
.grouper-toggle {
  display: inline-block;
  vertical-align: top;
}
.inscription-moment-indicator {
  padding: 0;
  margin-right: 0;
}
.list-header,
.list-line {
  padding: 2px 5px;
  line-height: 1.1;
}

.list-line.clocking-done {
  background: #c3ffd3;
}
.list-line.still-here {
  background: #fbffac;
}
.list-line.has-absence {
  background: #fad4d4;
}
.list-line {
  border-bottom: solid 1px #ccc;
}

.words > span {
  margin-right: 2px;
}
select.filterSet {
  background: #ffca22;
}
.dark-line {
  border-bottom-color: #222;
}
.dark-line:last-of-type {
  border-bottom-color: #ccc;
}
.show-birth-date {
  padding: 1px 4px;
}
.show-birth-date:hover {
  background: #ccc;
}
.seance-col {
  text-align: center;
  font-size: 11px;
  width: 36px;
  display: inline-block;
  overflow: hidden;
  vertical-align: top;
}
.seance-col-label {
  font-size: 10px;
  border-top: solid 1px #444;
  border-bottom: solid 1px #444;
  border-left: solid 1px #444;
  width: 100%;
  display: inline-block;
  padding: 4px 0;
}
.seance-col:last-of-type .seance-col-label{
  border-right: solid 1px #444;
}

.confirm-updated-inscriptions {
  margin: 0;
  border-top: solid 1px #444;
  padding: 5px 50px 5px 100px;
  background: #e3c168;
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
}
.duration-col {
  display: inline-block;
  font-size: 13px;
  padding: 2px;
  border: solid 1px #000;
  margin: 0 1px;
  min-width: 32px;
  text-align: center;
}
.duration-col-label {
  display: inline-block;
  font-size: 8px;
  padding: 2px;
  margin: 0 1px;
  min-width: 32px;
  text-align: center;
}
.header-block {
  padding: 5px;
  background: #444;
  color: #fff;
  margin-top: 2px;
}
.badge-absences-count {
  margin-left: 4px;
  margin-top: -2px;
  display: inline-block;
}
.elt-entity {
  padding: 1px 4px;
  border: solid 1px #444;
  cursor: pointer;
  display: inline-block;
  margin-bottom: 2px;
  margin-right: 2px;
}
.elt-entity.active-entity {
  background: #444;
  color: #fff;
}

</style>
